import React from "react";
import { Appstore, MobileAppImage, Playstore } from "../../utils/assetExports";

export default function StepTwo() {
  return (
    <div className="flex flex-col sm:flex-row rounded-[20px] overflow-hidden my-4">
      <div className="flex flex-col justify-center pr-[100px] bg-[#DE5F2C] p-4 md:p-8 ">
        <p className="text-white text-[32px] font-semibold">
          Find Your Perfect Part-Time Gig{" "}
          <span className="text-[#FFBF21]">Today</span>
        </p>
        <p className="text-white pt-[20px] ">
          Download the GIG Search Mobile app now to find a Part time Job that
          fits you.
        </p>
        {/* <div className="flex flex-row gap-4 pt-4">
          <img src={Playstore} />
          <img src={Appstore} />
        </div> */}
      </div>
      <div className="">
        <img className="object-cover" src={MobileAppImage} alt="" width={620} />
      </div>
    </div>
  );
}

import React from "react";
import {
  HeroImage01,
  HeroImage02,
  HeroImage03,
  HeroImage04,
  HeroImage05,
  HeroImage06,
  HeroImage07,
  HeroImage08,
  HeroImage09,
  HeroImage10,
  HeroImage11,
  HeroImage12,
  HeroImage13,
} from "../../utils/assetExports";
import Slider from "./Slider";

export default function HeroImgComponent() {
  const images = [
    HeroImage01,
    HeroImage02,
    HeroImage03,
    HeroImage04,
    HeroImage05,
    HeroImage06,
    HeroImage07,
    HeroImage08,
    HeroImage09,
    HeroImage10,
    HeroImage11,
    HeroImage12,
    HeroImage13,
    HeroImage01,
    HeroImage02,
    HeroImage03,
    HeroImage04,
    HeroImage05,
    HeroImage06,
    HeroImage07,
    HeroImage08,
    HeroImage09,
    HeroImage10,
    HeroImage11,
    HeroImage12,
    HeroImage13,
    HeroImage01,
    HeroImage02,
    HeroImage03,
    HeroImage04,
    HeroImage05,
    HeroImage06,
    HeroImage07,
    HeroImage08,
    HeroImage09,
    HeroImage10,
    HeroImage11,
    HeroImage12,
    HeroImage13,
  ];
  return (
    <div className="relative flex flex-col items-center overflow-hidden h-[86vh] justify-center">
      <Slider images={images} />
      <div className="z-10 flex flex-col items-center mb-2 p-10 md:w-2/3">
        <h1 className="text-white text-[22px] text-center font-bold sm:text-[52px] bg-orange px-2 bg-opacity-20 drop-shadow-lg mb-6 md:mb-1">
          Revolutionizing How You Find Work and Talent
        </h1>
        <p className="text-white text-[12px] text-center sm:font-medium sm:text-[16px] sm:py-[20px] drop-shadow-xl ">
          The GIG search mobile applications empower employers to post job
          openings ad-hoc to attract talented workers and enable job seekers to
          find work that fits their schedule, all without compromising your
          university life.
        </p>
      </div>
    </div>
  );
}
